.text-on-image {
  position: absolute;
  right: 2%;
  left: 82%;
  bottom: 40%;
  border-radius: 5px;
  background-color: rgba(15, 15, 15, 0.3);
}

.text-on-image-light {
  position: absolute;
  right: 2%;
  left: 82%;
  bottom: 40%;
  border-radius: 5px;

  background-color: rgba(255, 255, 255, 0.438);
}
